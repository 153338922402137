import {playlist_navigationStructureAssignVars} from "../_navigation-helpers";


export function view_navigation_generateNavigationItem(structureMenuItemContentInner, $currentItemFeed, configObj, navAttributes, isUltiboxItem = false){

  let final_structureMenuItemContent = '';


  if (structureMenuItemContentInner) {
    structureMenuItemContentInner = playlist_navigationStructureAssignVars($currentItemFeed, structureMenuItemContentInner);
    // -- add parent default skin todo: we will have navigation skin
    structureMenuItemContentInner = structureMenuItemContentInner.replace('<div class="layout-builder--structure', '<div class="layout-builder--structure layout-builder--parent-style-' + navAttributes.parentSkin);
  }


  let navigationItemDomTag = 'div';
  let navigationItemExtraAttr = ' ';
  let navigationItemExtraClasses = ' ';


  if ($currentItemFeed.data('dzsvg-curatedtype-from-gallery') === 'link') {
    navigationItemDomTag = 'a';
    if ($currentItemFeed.attr('data-source')) {
      navigationItemExtraAttr += ' href="' + $currentItemFeed.attr('data-source') + '"';
    }
    if ($currentItemFeed.attr('data-target')) {
      navigationItemExtraAttr += ' target="' + $currentItemFeed.attr('data-target') + '"';
    }
  }


  if(isUltiboxItem){
    navigationItemExtraClasses+=' ultibox-item-delegated';

    if($currentItemFeed.hasClass('vplayer-tobe')){



      navigationItemExtraAttr+=' data-type="video"';
      if($currentItemFeed.attr('data-type')){

        navigationItemExtraAttr+=` data-video-type="${$currentItemFeed.attr('data-type')}"`;
      }
      if($currentItemFeed.attr('data-sourcevp')){

        navigationItemExtraAttr+=` data-source="${$currentItemFeed.attr('data-sourcevp')}"`;
      }

    }
  }

  // -- generating final_structureMenuItemContent

  final_structureMenuItemContent += '<' + navigationItemDomTag + ' class=" dzs-navigation--item ';
  final_structureMenuItemContent+=navigationItemExtraClasses;
  final_structureMenuItemContent+='"';
  final_structureMenuItemContent += navigationItemExtraAttr;
  final_structureMenuItemContent += '>';
  final_structureMenuItemContent += '<div class=" dzs-navigation--item-content">';


  final_structureMenuItemContent += structureMenuItemContentInner + '</div>';


  final_structureMenuItemContent += '</' + navigationItemDomTag + '>';


  // -- function
  if (configObj.filter_structureMenuItemContent) {
    final_structureMenuItemContent = configObj.filter_structureMenuItemContent(final_structureMenuItemContent, $currentItemFeed);
  }

  return final_structureMenuItemContent;

}