export const defaultSettings = {
  navigationType: 'hover', // -- "hover" or "scroller" or "thumbsAndArrows" or "simple"
  navigationSkin: 'skin-default',
  menuPosition: 'bottom',
  navigation_direction: 'auto', // -- "auto" or "vertical" or "horizontal"
  menuItemWidth: 'default', // -- number or "default"
  menuItemHeight: 'default',
  navigationSpace: undefined,
  feedItemNotInitedClass: '.vplayer-tobe',
  isAutoScrollToCurrent: false,
  isSyncMainAreaAndNavigationAreas: true,
  viewEnableMediaArea: true,
  viewNavigationIsUltibox: false,
  isUseEasing: true,
  parentSkin: '',
  filter_structureMenuItemContent: null,
  viewAnimationDuration: null,
  navigationStructureHtml: '',
  gridClassItemsContainer: '', // -- used for "simple" or "scroller"
}


export const NAVIGATION_DEFAULT_TIMEOUT = 320;